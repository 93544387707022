// Importation des styles et des modules
import '../scss/main.scss';



import { initBootstrap } from "./bootstrap.js"; // Chargement de l'initialisation de Bootstrap
import { bottom, flip, viewport } from '@popperjs/core'; // Modules Popper.js
import { gsap } from "gsap"; // GreenSock Animation Platform pour les animations
import { ScrollSmoother } from 'gsap/ScrollSmoother.js';
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js';
import { SplitText } from 'gsap/SplitText.js';
import { dpkCursor } from 'dpk_cursor';


// Exportation de initBootstrap pour utilisation dans d'autres fichiers
// export { initBootstrap, bottom, viewport };






/////////////////////
// REGISTER PLUGIN //
/////////////////////
gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);






///////////////////
// SMOOTH SCROLL //
///////////////////
let smoother = ScrollSmoother.create({
  wrapper: '#smooth-wrapper',
  content: '#smooth-content',
  smooth: 2,
  smoothTouch: 0.5,
  effects: true,
})


////////////////
// DPK CURSOR //
////////////////
// const customCursor = new dpkCursor({ ease: 0.25 });

///////////////////
// DETECT MOBILE //
///////////////////

// if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
//   // document.write("mobile");
//   customCursor.destroy();
//   // ScrollSmoother.destroy(); DOESN'T EXIST

// } else {
//   // document.write("not mobile");
// }

////////////////
// SPLIT TEXT //
////////////////
// V1
// // Diviser le texte en lignes
// const splitText = new SplitText(".reveal-text", { type: "lines" });
// gsap.from(splitText.lines, {
//   scrollTrigger: {
//     trigger: ".reveal-section",
//     start: "top 80%", // Ajustement du point de déclenchement
//     toggleActions: "play none none reset"
//   },
//   opacity: 0,
//   y: 50, // Les lignes remontent
//   stagger: 0.1, // Délai entre chaque ligne pour un effet de cascade
//   duration: 0.8,
//   ease: "power3.out"
// });

// Divise le texte en mots, puis chaque mot en lettres
const splitText = new SplitText("#apropos .reveal-text", { type: "words, chars" });
const chars = splitText.chars;

// Crée une timeline pour l'animation
const tl = gsap.timeline({
  scrollTrigger: {
    trigger: "#apropos",
    start: "top top", 
    end: "bottom top", 
    scrub: true,
    pin: true,
    anticipatePin: 1,
  }
});

// Définit les caractères en gris clair initialement
gsap.set(chars, { color: "#d3d3d3" });

// Anime progressivement chaque caractère du gris clair au noir
tl.to(chars, {
  color: "#000",
  duration: 1,
  stagger: 0.8,
  ease: "none"
});



//////////
// LOGO //
//////////

gsap.to(".logo", {
  rotation: 360,   // Rotation en degrés
  duration: 30,     // Durée d'une rotation complète en secondes
  repeat: -1,      // Boucle infinie
  ease: "linear"   // Rotation fluide
});

//////////
// HERO //
//////////

// Animation de l'image d'arrière-plan avec un léger zoom
gsap.to("#intro img", {
  scale: 1.1, // Zoom léger
  duration: 8,
  ease: "power2.out",
});

// // Animation pour le texte de l'overlay
// gsap.from("#intro .overlay-content h1", {
//   opacity: 0,
//   y: 50, // Le texte remonte légèrement
//   stagger: 0.3, // Délai progressif entre les titres "CYRILLE" et "BROTTO"
//   duration: 1,
//   ease: "power2.out",
// });

// // Animation pour le sous-texte
// gsap.from("#intro .overlay-content p", {
//   opacity: 0,
//   y: 30,
//   delay: 0.8, // Légère pause après les titres
//   duration: 1,
//   ease: "power2.out",
// });

// // Animation pour les icônes de réseaux sociaux
// gsap.from("#intro .overlay-content .col a", {
//   opacity: 0,
//   scale: 0.5,
//   stagger: 0.2, // Effet d'apparition en séquence
//   delay: 1.2, // Attend que le texte soit visible
//   duration: 0.8,
//   ease: "back.out(1.7)",
// });

/////////////////
// INTRO HOVER //
/////////////////

// Sélection de la zone de texte overlay, de l'image, et des éléments de texte
const overlay = document.querySelector("#intro .overlay-content");
const bgImage = document.querySelector("#intro img");
const textElements = document.querySelectorAll("#intro .overlay-content h1, #intro .overlay-content p");

// Affichage immédiat du texte
textElements.forEach((el) => {
  gsap.set(el, { opacity: 1, y: 0 }); // S'assurer que le texte est visible dès le départ
});

// Animation GSAP au survol
overlay.addEventListener("mouseenter", () => {
  // Animation de passage en couleur pour l'image
  gsap.to(bgImage, {
    filter: "grayscale(0%)", // Passe en couleur
    duration: 0.5,
    ease: "power2.out",
  });

  // Animation subtile pour le texte
  gsap.to(textElements, {
    scale: 1.05, // Légère mise à l'échelle
    duration: 0.5,
    ease: "power2.out",
  });
});

// Réinitialisation de l'échelle du texte quand on quitte le survol
overlay.addEventListener("mouseleave", () => {
  // Retour au noir et blanc pour l'image
  gsap.to(bgImage, {
    filter: "grayscale(100%)", // Noir et blanc
    duration: 0.5,
    ease: "power2.out",
  });

  // Retour à l'échelle initiale pour le texte
  gsap.to(textElements, {
    scale: 1, // Retourne à la taille normale
    duration: 0.5,
    ease: "power2.out",
  });
});

///////////
// TITRE //
///////////




//////////
// MENU //
//////////

document.addEventListener("DOMContentLoaded", () => {
  const toggleMenu = document.getElementById("toggleMenu");
  const fullscreenMenu = document.getElementById("fullscreenMenu");
  const menuLinks = document.querySelectorAll(".menu-link");

  // Initialise l'opacité du menu
  gsap.set(fullscreenMenu, { opacity: 0 });

  toggleMenu.addEventListener("click", () => {
    toggleMenu.classList.toggle("active");

    if (toggleMenu.classList.contains("active")) {
      // Ouvre le menu avec une animation d'opacité
      fullscreenMenu.classList.add("active");
      gsap.to(fullscreenMenu, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.out"
      });
    } else {
      // Ferme le menu avec une animation d'opacité
      gsap.to(fullscreenMenu, {
        opacity: 0,
        duration: 0.5,
        ease: "power2.in",
        onComplete: () => fullscreenMenu.classList.remove("active")
      });
    }
  });

  // Ferme le menu lorsque l'on clique sur un lien
  menuLinks.forEach(link => {
    link.addEventListener("click", () => {
      toggleMenu.classList.remove("active");
      gsap.to(fullscreenMenu, {
        opacity: 0,
        duration: 0.5,
        ease: "power2.in",
        onComplete: () => fullscreenMenu.classList.remove("active")
      });
    });
  });
});


///////////
// ASIDE //
///////////

const indicateurs = document.querySelectorAll(".indicator, .indicator-menu");
const sections = document.querySelectorAll("section");

const reinitialiserIndicateurActif = () => {
  const indicateurActif = document.querySelector(".indicator.active, .indicator-menu.active");
  if (indicateurActif) {
    indicateurActif.classList.remove("active");
  }
};

const surSectionEntreDansViewport = (section) => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          reinitialiserIndicateurActif();
          const element = entry.target;
          const indicateur = document.querySelector(`a[href='#${element.id}'].indicator, a[href='#${element.id}'].indicator-menu`);
          if (indicateur) {
            indicateur.classList.add("active");
          }
        }
      });
    },
    {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,  // Déclenche lorsque 20% de la section est visible
    }
  );
  observer.observe(section);
};

sections.forEach(surSectionEntreDansViewport);

// Ajouter un comportement de défilement fluide lors du clic sur un indicateur
indicateurs.forEach((indicateur) => {
  indicateur.addEventListener("click", function (event) {
    event.preventDefault();
    smoother.scrollTo(this.getAttribute("href"), true, "top top");
    reinitialiserIndicateurActif();
    this.classList.add("active");
  });
});



//

let intro = document.querySelector(".intro");
intro.addEventListener("click", e => {
  smoother.scrollTo("#intro", true, "top top")
});

let bandsintown = document.querySelector(".bandsintown");
bandsintown.addEventListener("click", e => {
  smoother.scrollTo("#bandsintown", true, "top top")
});

let projets = document.querySelector(".projets");
projets.addEventListener("click", e => {
  smoother.scrollTo("#projets", true, "top top")
});

let apropos = document.querySelector(".apropos");
apropos.addEventListener("click", e => {
  smoother.scrollTo("#apropos", true, "top top")
});

// INDICATOR MENU



//////////////
// PIN CARD //
//////////////


const projetsTitre = document.querySelector('.projetsTitre'); // Sélectionnez votre carte
const dimensionsProjetsTitre = projetsTitre.getBoundingClientRect();

const carte = document.querySelector('.carte1'); // Sélectionnez votre carte
// Obtenez les dimensions et la position de l'élément
const rect = carte.getBoundingClientRect();


// const cards = document.querySelectorAll('.carte'); // Supposez que toutes vos cartes ont la classe "card"
const offset = -80; 

ScrollTrigger.create({
  trigger: ".projetsTitre",
  start: `top top`,
  pin: true,
  pinSpacing: false,
  // markers: true,
  end: `+=${rect.height * 3 + offset*3 + dimensionsProjetsTitre}`,
});





ScrollTrigger.create({
  trigger: ".carte1",
  start: `top+=${-dimensionsProjetsTitre.height} top`,
  pin: true,
  pinSpacing: false,
  // markers: true,
  end: `+=${rect.height * 3 + offset*3}`,
});

ScrollTrigger.create({
  trigger: ".carte2",
  start: `top+=${-dimensionsProjetsTitre.height + offset} top`,
  pin: true,
  pinSpacing: false,
  // markers: true,
  end: `+=${rect.height * 2 + offset*2}`,
});

ScrollTrigger.create({
  trigger: ".carte3",
  start: `top+=${-dimensionsProjetsTitre.height + offset*2} top`,
  pin: true,
  pinSpacing: false,
  // markers: true,
  end: `+=${rect.height + offset}`,
});

ScrollTrigger.create({
  trigger: ".carte4",
  start: `top+=${3 * offset + dimensionsProjetsTitre.height} top`,
  pin: true,
  pinSpacing: false,
  // markers: true,
  end: `+=${-rect.height - offset + dimensionsProjetsTitre}`,
});



//////////////////
// PLAYER AUDIO //
//////////////////

window.addEventListener('load', function() {
    const audio = document.getElementById('audio');
    const playPauseBtn = document.getElementById('playPauseBtn');
    const prevBtn = document.getElementById('prevBtn');
    const nextBtn = document.getElementById('nextBtn');
    const playIcon = document.getElementById('playIcon');
    const pauseIcon = document.getElementById('pauseIcon');
    const currentTimeSpan = document.getElementById('currentTime');
    const totalTimeSpan = document.getElementById('totalTime');
    const currentTrackTitle = document.getElementById('currentTrackTitle');
    const playlist = document.getElementById('playlist');
    const tracks = Array.from(playlist.querySelectorAll('li'));
    let currentTrackIndex = 0;
    let shuffledTracks = shuffleArray(tracks);
  
    loadTrack(currentTrackIndex);
  
    playPauseBtn.addEventListener('click', function () {
        if (audio.paused) {
            audio.play();
            togglePlayPauseIcons(true);
        } else {
            audio.pause();
            togglePlayPauseIcons(false);
        }
    });
  
    nextBtn.addEventListener('click', function () {
        nextTrack();
    });
  
    prevBtn.addEventListener('click', function () {
        prevTrack();
    });
  
    audio.addEventListener('timeupdate', function () {
        currentTimeSpan.textContent = formatTime(audio.currentTime);
        if (!isNaN(audio.duration)) {
            totalTimeSpan.textContent = formatTime(audio.duration);
        }
    });
  
    audio.addEventListener('ended', function () {
        nextTrack();
    });
  
    // Function to shuffle an array
    function shuffleArray(array) {
        let shuffled = array.slice();
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    }
  
    function loadTrack(index) {
        const selectedTrack = shuffledTracks[index];
        audio.src = selectedTrack.getAttribute('data-src');
        currentTrackTitle.textContent = selectedTrack.textContent;
        togglePlayPauseIcons(false);
    }
    
  
    function nextTrack() {
        currentTrackIndex = (currentTrackIndex + 1) % shuffledTracks.length;
        loadTrack(currentTrackIndex);
        audio.play();
        togglePlayPauseIcons(true);
    }
  
    function prevTrack() {
        currentTrackIndex = (currentTrackIndex - 1 + shuffledTracks.length) % shuffledTracks.length;
        loadTrack(currentTrackIndex);
        audio.play();
        togglePlayPauseIcons(true);
    }
  
    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    }
  
    function togglePlayPauseIcons(isPlaying) {
        if (isPlaying) {
            playIcon.style.display = 'none';
            pauseIcon.style.display = 'inline';
        } else {
            playIcon.style.display = 'inline';
            pauseIcon.style.display = 'none';
        }
    }
  });
  
  
/////////////////
// ANIME TEXTE //
/////////////////

// Variables pour les mots à alterner
const words = ["musicien", "accordéon diatonique"];
let currentIndex = 0;

// Sélectionner le conteneur du texte dans le HTML
const wordContainer = document.getElementById("animatedText");

// Fonction pour générer un caractère spécial aléatoire
function getRandomChar() {
    const specialChars = "!@#$%^&*()_+{}[];:,.<>?";
    return specialChars[Math.floor(Math.random() * specialChars.length)];
}

// Fonction pour animer un mot avec des caractères aléatoires avant de revenir au texte final
function animateWord(word) {
    wordContainer.textContent = ''; // Vider le conteneur pour le nouveau mot

    // Créer des spans pour chaque caractère du mot
    const spans = [];
    for (let i = 0; i < word.length; i++) {
        const span = document.createElement('span');
        span.textContent = getRandomChar(); // Initialiser avec un caractère aléatoire
        wordContainer.appendChild(span);
        spans.push(span); // Stocker les spans pour une utilisation ultérieure
    }

    // Animation pour chaque caractère
    spans.forEach((span, i) => {
        // Variation des caractères spéciaux
        gsap.to(span, {
            duration: 0.1,
            repeat: -1, // Répéter indéfiniment jusqu'à ce que l'animation soit terminée
            onRepeat: () => {
                span.textContent = getRandomChar(); // Changer en un caractère spécial aléatoire
            },
            delay: i * 0.05, // Décalage pour chaque caractère
        });

        // Remplacer par le bon caractère final après un certain temps
        gsap.to(span, {
            duration: 0.5, // Temps total avant de finaliser avec le bon caractère
            delay: i * 0.05 + 0.5, // Décalage pour afficher le bon caractère après la variation
            onComplete: () => {
                span.textContent = word[i]; // Remplacer par le caractère correct
                gsap.killTweensOf(span); // Arrêter l'animation pour ce span
            }
        });
    });

    // Pause après l'affichage complet du mot avant de passer au suivant
    setTimeout(() => {
        spans.forEach((span, i) => {
            // S'assurer que le caractère final est affiché
            gsap.killTweensOf(span); // Arrêter toute animation en cours
            span.textContent = word[i]; // S'assurer que le caractère final est affiché
        });
    }, (word.length * 0.05) + 500 + 1000); // Pause de 1 seconde après l'affichage du mot
}

// Fonction principale pour alterner les mots
function changeWord() {
    // Récupérer le mot actuel
    const currentWord = words[currentIndex];

    // Changer d'index pour le prochain mot
    currentIndex = (currentIndex + 1) % words.length; // Passer au mot suivant

    // Animer le mot actuel
    animateWord(currentWord);
}

// Appeler la fonction pour le premier mot et alterner toutes les 4 secondes
changeWord();
setInterval(changeWord, 3000); // Intervalle de 4 secondes pour inclure la pause
